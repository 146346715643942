'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider",
        function($stateProvider) {
            $stateProvider
                .state('index.subscription', {
                    url: '/subscription',
                    templateUrl: 'app/subscription/subscription.html',
                    controller: 'SubCtrl'
                })
                .state('index.subscriptionDetails', {
                    url: '/subscription_details/:subscriptionId',
                    templateUrl: 'app/subscription/subscription_details.html',
                    controller: 'SubCtrl'
                })
                .state('index.subscriptionOrderDetails', {
                    url: '/subscription_order_details/:subId',
                    templateUrl: 'app/subscription/subscription_order_details.html',
                    controller: 'SubCtrl'
                })
                .state('index.subscriptionHistory', {
                    url: '/subscription/history',
                    templateUrl: 'app/subscription/history.html',
                    controller: 'SubCtrl'
                })
                .state('main.subscriptions', {
                    url: '/available_subscriptions',
                    templateUrl: 'app/subscription/guest/subscriptions.html',
                    controller: 'SubscriptionCtrl'
                })
                .state('main.subscription-details', {
                    url: '/subscription/:id',
                    templateUrl: 'app/subscription/guest/subscription.details.html',
                    controller: 'SubscriptionCtrl'
                })
                .state('index.subscription-plan-details', {
                    url: '/subscription/:plan',
                    templateUrl: 'app/subscription/subscription.html',
                    controller: 'SubCtrl'
                })
                 .state('main.accesscode-plan-details', {
                    url: '/accesscode_subscription?/:id',
                    params: {
                     accesscode: null,
                     txnid:null
                   },
                    templateUrl: 'app/subscription/guest/accesscode_plan_details.html',
                    controller: 'AccesscodeCtrl'
                });
        }
    ]);